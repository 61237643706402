<template>
  <span>
    <b-link v-if="item.target_fqdn" :to="`/dnsvs/fqdns/${item.data}`">
      <code class="text-wrap mr-2" style="max-width: 350px; display: inline-block">{{ item.data }}</code>
    </b-link>
    <code v-else class="text-wrap mr-2" style="max-width: 350px; display: inline-block">{{ item.data }}</code>
    <b-badge v-if="!item.target_is_singleton" variant="warning" class="mr-1">{{
        $t('components.rrdata_view.rr_set')
      }}</b-badge>
    <b-badge v-if="item.target_is_reverse_unique" class="mr-1"
             :title="$t('components.rrdata_view.reverse_unique')">{{ $t('components.rrdata_view.ptr') }}</b-badge>
    <IPAddressTypeBadge v-if="subnets" :subnets="subnets" :record_address="item.data" class="mr-1"/>
  </span>
</template>
<script>
import IPAddressTypeBadge from '@/components/IPAddressTypeBadge.vue'

export default {
  name: 'RRDataView',
  components: {IPAddressTypeBadge},
  props: {
    item: {
      required: true
    },
    subnets: {
      required: true,
      type: Array
    }
  }
}
</script>
