import TransactionService from './transaction.service'

export default {
  getTypes(config) {
    const ta = [
      {
        idx: 'inttype_pre',
        name: 'dnscfg.record_inttype.list',
        old: {
          is_own: true,
          record_type_is_addr_based: false,
          record_type_is_name_based: false
        }
      },
      {
        name: 'dnscfg.record_inttype.list',
        old: {
          is_own: true,
          sorting_params_list: ['record_type']
        },
        anti_join_ref: { inttype_pre: 'self' }
      },
      { idx: 'record_types_pre', name: 'dnscfg.record_type.list', old: { is_addr_based: false, is_name_based: false } },
      { name: 'dnscfg.record_type.list', anti_join_ref: { record_types_pre: 'self' } },
      { name: 'dnscfg.fqdn_type.list', old: { sorting_params_list: ['position'] } },
      {
        idx: 'inttype_pre_non_bcd',
        name: 'dnscfg.record_inttype.list',
        old: {
          is_own: true,
          record_type_is_addr_based: false,
        }
      },
      { idx: 'non_bcd_types', name: 'dnscfg.record_type.list', inner_join_ref: { inttype_pre_non_bcd: 'default' }, old: { sorting_params_list: ['name'] } },
      {
        idx: 'myinttype_pre',
        name: 'dnscfg.record_inttype.list',
        old: {
          is_own: true,
        }
      },
      { idx: 'my_record_types', name: 'dnscfg.record_type.list', inner_join_ref: { myinttype_pre: 'default' }, old: { sorting_params_list: ['name'] } },
    ]
    return TransactionService.execute(config, ta)
  },
  getOuTypes(config) {
    const ta = [
      {
        name: 'org.unit_type.list',
      }
    ]
    return TransactionService.execute(config, ta)
  }
}
