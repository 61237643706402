<template>
  <div>
    <b-dropdown variant="light" boundary="window">
      <template v-slot:button-content>
        <netvs-icon icon="info"></netvs-icon>
      </template>
      <b-dropdown-item :to="'/dnsvs/fqdns/' + item.fqdn + '/records'">{{$t('components.record_info.records_to_fqdn')}}
      </b-dropdown-item>
      <b-dropdown-item :to="'/dnsvs/fqdns/' + item.fqdn + '/references'">{{$t('components.record_info.reference_to_fqdn')}}
      </b-dropdown-item>
      <b-dropdown-item :to="'/dnsvs/ips/' + item.target_ipaddr + '/records'" v-if="item.target_ipaddr">
        {{$t('components.record_info.records_to_ip')}}
      </b-dropdown-item>
      <b-dropdown-item :to="'/dnsvs/ips/' + ptr_to_addr(item.fqdn) + '/records'" v-if="item.type === 'PTR' && is_ptr(item.fqdn)">
        {{$t('components.record_info.records_to_ip')}}
      </b-dropdown-item>
      <b-dropdown-item v-if="item.target_fqdn" :to="'/dnsvs/fqdns/' + item.target_fqdn+ '/records'">
        {{$t('components.record_info.records_to_target_fqdn')}}
      </b-dropdown-item>
      <b-dropdown-item v-if="item.target_fqdn" :to="'/dnsvs/fqdns/' + item.target_fqdn+ '/references'">
        {{$t('components.record_info.reference_to_target_fqdn')}}
      </b-dropdown-item>
      <b-dropdown-item v-if="$store.state.developer" :to="'/object/' + item.gpk">
        <netvs-developer-marker></netvs-developer-marker> {{ $t('system.object_explorer') }} ({{ $tc('system.record', 1) }})
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import ipaddress from '@/util/ipaddress'
import NetvsDeveloperMarker from '@/components/NETVSDeveloperMarker.vue'

export default {
  name: 'RecordInfo',
  components: { NetvsDeveloperMarker },
  props: {
    item: {
      required: true
    }
  },
  methods: {
    ptr_to_addr: ipaddress.ptr_to_addr,
    is_ptr: ipaddress.is_ptr
  }
}
</script>

<style>

</style>
