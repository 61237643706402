// -- AUTOGENERATED --

// eslint-disable-next-line no-unused-vars
import APIUtils from '@/util/apiutil'

import Axios from 'axios'
import NETVSConfig from '@/../netvs.config'

/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable object-curly-spacing */
/* eslint-disable array-bracket-spacing */
/* eslint-disable object-shorthand */
export default {

  createParamsList () {
    return {'old': [ ], 'new': [ 'ttl', 'data', 'fqdn', 'type', 'ttl_reset_days', 'target_is_singleton', 'target_is_reverse_unique']}
  },
  create (config, {ttl_new, data_new, fqdn_new, type_new, ttl_reset_days_new, target_is_singleton_new, target_is_reverse_unique_new}) {
    const params = { 'new': { 'ttl': ttl_new, 'data': data_new, 'fqdn': fqdn_new, 'type': type_new, 'ttl_reset_days': ttl_reset_days_new, 'target_is_singleton': target_is_singleton_new, 'target_is_reverse_unique': target_is_reverse_unique_new}, 'old': { }}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/dns/record/create`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  deleteParamsList () {
    return {'old': [ 'data', 'fqdn', 'type', 'try_del_fqdn', 'force_del_ref_records'], 'new': [ ]}
  },
  delete (config, {data_old, fqdn_old, type_old, try_del_fqdn_old = true, force_del_ref_records_old = false}) {
    const params = { 'new': { }, 'old': { 'data': data_old, 'fqdn': fqdn_old, 'type': type_old, 'try_del_fqdn': try_del_fqdn_old, 'force_del_ref_records': force_del_ref_records_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/dns/record/delete`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  impParamsList () {
    return {'old': [ 'fqdn'], 'new': [ 'fqdn', 'fqdn_type', 'import_data', 'strict_mode', 'fqdn_description']}
  },
  imp (config, {fqdn_old, fqdn_new, fqdn_type_new, import_data_new, strict_mode_new = true, fqdn_description_new}) {
    const params = { 'new': { 'fqdn': fqdn_new, 'fqdn_type': fqdn_type_new, 'import_data': import_data_new, 'strict_mode': strict_mode_new, 'fqdn_description': fqdn_description_new}, 'old': { 'fqdn': fqdn_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/dns/record/imp`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  list (config, { gpk, data, type, zone, is_own, is_auth, gpk_list, data_list, do_nn_ttl = false, fqdn_list, type_list, data_regexp, fetch_limit, fqdn_regexp, fetch_offset, fqdn_label_list, fqdn_label_regexp, filter_params_dict, sorting_params_list, target_is_singleton, target_is_reverse_unique, rr_chain_target_subnet_gfk_list }) {
    const params = APIUtils.denullify_dict({ 'gpk': gpk, 'data': data, 'type': type, 'zone': zone, 'is_own': is_own, 'is_auth': is_auth, 'gpk_list': (gpk_list === null) ? null : JSON.stringify(gpk_list), 'data_list': (data_list === null) ? null : JSON.stringify(data_list), 'do_nn_ttl': do_nn_ttl, 'fqdn_list': (fqdn_list === null) ? null : JSON.stringify(fqdn_list), 'type_list': (type_list === null) ? null : JSON.stringify(type_list), 'data_regexp': data_regexp, 'fetch_limit': fetch_limit, 'fqdn_regexp': fqdn_regexp, 'fetch_offset': fetch_offset, 'fqdn_label_list': (fqdn_label_list === null) ? null : JSON.stringify(fqdn_label_list), 'fqdn_label_regexp': fqdn_label_regexp, 'filter_params_dict': filter_params_dict, 'sorting_params_list': (sorting_params_list === null) ? null : JSON.stringify(sorting_params_list), 'target_is_singleton': target_is_singleton, 'target_is_reverse_unique': target_is_reverse_unique, 'rr_chain_target_subnet_gfk_list': (rr_chain_target_subnet_gfk_list === null) ? null : JSON.stringify(rr_chain_target_subnet_gfk_list)})
    const cnf = {}
    Object.assign(cnf, (config || {}).netdb_axios_config)
    cnf.params = params
    return Axios.get(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/dns/record/list`, cnf)
  // eslint-disable-next-line comma-dangle
  },
  updateParamsList () {
    return {'old': [ 'data', 'fqdn', 'type', 'try_del_fqdn'], 'new': [ 'ttl', 'data', 'fqdn', 'type', 'ttl_reset_days', 'target_is_singleton', 'target_is_reverse_unique']}
  },
  update (config, {ttl_new, data_old, data_new, fqdn_old, fqdn_new, type_old, type_new, try_del_fqdn_old = true, ttl_reset_days_new, target_is_singleton_new, target_is_reverse_unique_new}) {
    const params = { 'new': { 'ttl': ttl_new, 'data': data_new, 'fqdn': fqdn_new, 'type': type_new, 'ttl_reset_days': ttl_reset_days_new, 'target_is_singleton': target_is_singleton_new, 'target_is_reverse_unique': target_is_reverse_unique_new}, 'old': { 'data': data_old, 'fqdn': fqdn_old, 'type': type_old, 'try_del_fqdn': try_del_fqdn_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/dns/record/update`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
}
