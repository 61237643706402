<template>
  <BundledDBEditor v-if="template && record_types_by_name" object_function="create" object_fq_name="dns.record"
                          :modal_id="modal_id"
                          :template="template"
                          :presets="presets"
                          :input_reducer="reducer"
                          :prepend="true"
                          :non_optionals_order="['fqdn', 'type', 'data', 'fqdn_description', 'fqdn_description_overwrite']"/>
</template>

<script>
import BundledDBEditor from '@/components/db-editor/BundledDBEditor.vue'
import TypeService from '@/api-services/dnscfg_types.service'
import apiutil from '@/util/apiutil'
import Axios from 'axios'

export default {
  name: 'CreateDNSRecord',
  components: { BundledDBEditor },
  async created() {
    this.template = (await Axios.get('/bundled_json_templates/prepend_dns_record_create.json')).data
    const typeQuery = await TypeService.getTypes(this.$store.state.netdb_axios_config)
    this.record_types_by_name = apiutil.dict_by_value_of_array(typeQuery.data.my_record_types, 'name')
    const types = typeQuery.data.my_record_types
    const selections = {}
    const fqdnSelections = {}
    for (const t of types) {
      selections[t.name] = { display_name: t.name }
    }
    for (const t of typeQuery.data.fqdn_type_list) {
      let disp_name = t.description
      disp_name += ' ['
      disp_name += t.is_nonterminal ? this.$t('views.dnsvs.bcd_records.non-terminal') : this.$t('views.dnsvs.bcd_records.terminal')
      disp_name += ']'
      fqdnSelections[t.name] = { display_name: disp_name }
    }
    this.full_create_record_reducer = {
      type: selections
    }
  },
  computed: {
    reducer() {
      const ret = this.full_create_record_reducer
      if (this.fixed_record_type && !this.$store.state.expert) {
        ret.type = false
      }
      if (this.fixed_fqdn && !this.$store.state.expert) {
        ret.fqdn = false
      } else {
        ret.fqdn = true
      }
      if (this.fixed_data && !this.$store.state.expert) {
        ret.data = false
      }
      return ret
    },
    presets() {
      const ret = {}
      if (this.fixed_record_type) {
        ret.type = this.fixed_record_type
        ret.target_is_reverse_unique = this.record_types_by_name[this.fixed_record_type].target_is_reverse_unique_dflt
        ret.target_is_singleton = this.record_types_by_name[this.fixed_record_type].target_is_singleton_dflt
      }
      if (this.fixed_fqdn) {
        ret.fqdn = this.fixed_fqdn
      }
      if (this.fixed_data || this.loose_data) {
        ret.data = this.fixed_data ? this.fixed_data : this.loose_data
      }
      return ret
    },
  },
  data() {
    return {
      template: null,
      full_create_record_reducer: {},
      record_types_by_name: null,
    }
  },
  props: {
    modal_id: {
      type: String,
      required: true
    },
    fixed_record_type: {
      type: String,
      required: false,
      default() {
        return null
      }
    },
    fixed_fqdn: {
      type: String,
      required: false,
      default() {
        return null
      }
    },
    fixed_data: {
      type: String,
      required: false,
      default() {
        return null
      }
    },
    loose_data: {
      type: String,
      required: false,
      default() {
        return null
      }
    },
  }
}
</script>

<style scoped>

</style>
