<template>
  <span>
    <b-badge v-if="subnets.map(a => a.default_gateway).includes(record_address)" variant="warning">{{ $t('system.gateway_address') }}</b-badge>
    <b-badge v-if="subnets.filter(a => is_ip_v4(ip_net_get_first(a.cidr)) && parseInt(a.cidr.split('/')[1]) < 31).map(a => ip_net_get_first(a.cidr)).includes(record_address)" variant="warning">{{ $t('system.network_address') }}</b-badge>
    <b-badge v-if="subnets.filter(a => is_ip_v4(ip_net_get_last(a.cidr)) && parseInt(a.cidr.split('/')[1]) < 31).map(a => ip_net_get_last(a.cidr)).includes(record_address)" variant="warning">{{ $t('system.broadcast_address') }}</b-badge>
    <b-badge v-if="subnets.filter(a => a.type === '6' && a.cidr.split('/')[1] === '64').map(a => a.cidr.split('/')[0]).includes(record_address)" variant="warning">{{ $t('system.router_anycast_address') }}</b-badge>
  </span>
</template>

<script>
import ipaddress from '@/util/ipaddress'

export default {
  name: 'IPAddressTypeBadge',
  props: {
    record_address: {
      required: true
    },
    subnets: {
      required: true,
      type: Array
    }
  },
  methods: {
    ip_net_get_first: (net) => ipaddress.ip_net_get_first(net),
    ip_net_get_last: (net) => ipaddress.ip_net_get_last(net),
    is_ip_v4: (addr) => ipaddress.is_ip_v4(addr)
  }
}
</script>
