<template>
  <b-dropdown boundary="window" @click="deleteRecord(record, false, true)"
              :id="'button-delete-record-' +  record.fqdn + '_' + record.type + '_' + record.data"
              split variant="outline-danger">
    <template #button-content>
      <netvs-icon icon="delete"></netvs-icon>
    </template>
    <b-dropdown-item @click="deleteRecord(record, false, false)">
      {{ $t('components.record_delete_button.delete_note_keep_fqdn') }}
    </b-dropdown-item>
    <b-dropdown-item @click="deleteRecord(record, true, true)">
      {{ $t('components.record_delete_button.delete_note') }}
    </b-dropdown-item>
    <b-tooltip
      :target="'button-delete-record-' +  record.fqdn + '_' + record.type + '_' + record.data"
      triggers="hover"
      variant="danger" placement="bottom">
      {{ $t('components.record_delete_button.delete_record') }}
    </b-tooltip>
  </b-dropdown>
</template>

<script>
import transactionutil from '@/util/transactionutil'
import RecordService from '@/api-services.gen/dns.record'
export default {
  name: 'RecordDeleteButton',
  methods: {
    deleteRecord: function (item, force_del_ref_records, try_del_fqdn) {
      const itc = {}
      Object.assign(itc, item)
      itc.force_del_ref_records = force_del_ref_records
      itc.try_del_fqdn = try_del_fqdn
      const ta = transactionutil.generateDeleteElement('dns.record', RecordService.deleteParamsList(), itc,
        item.type + '-' + this.$t('views.dnsvs.bcd_records.record_to_fqdn') + ' ' + item.fqdn +
        (force_del_ref_records ? ' ' + this.$t('components.record_delete_button.delete_note') : '') +
        (!try_del_fqdn ? ' ' + this.$t('components.record_delete_button.delete_note_keep_fqdn') : '')
      )
      this.$store.commit('addTransactionElement', ta)
      this.$emit('commited', ta)
      if (!this.isMobile() && !this.$store.state.show_sidebar_right) {
        this.$store.commit('showSidebarRight', true)
      }
    },
  },
  props: {
    record: {
      type: Object,
      required: true
    },
  }
}
</script>
